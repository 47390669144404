<script lang="ts">
    import Button from "./Button.svelte"
    import activeFloor from "../floors.js"

    export let image_id;
    export let name;

    function handleClick(event: MouseEvent) {
        activeFloor.set(image_id);
    }
</script>

<Button clickfunc={handleClick} id={"backimg-btn-"+image_id} classList={$activeFloor == image_id ? "btn-primary" : ""}>
    {name}
</Button>           