<script lang="ts">
    import { onMount } from "svelte";

    export let clickfunc: (e: MouseEvent) => void;
    export let id;
    export let classList;

    onMount(() => { 
        document.getElementById(id).onclick = clickfunc;
    });
</script>

<button class={"btn " + classList} id={id}>
    <slot/>
</button>